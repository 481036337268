<template>
  <v-card class="elevation-x">
    <v-sheet class="pa-2">
      <v-row align="center">
        <v-col cols="12" md="6">
          <v-text-field dense filled rounded placeholder="Recherche par nom" hide-details />
        </v-col>
        <v-spacer />
        <v-col cols="12" class="align-self-center d-none d-md-block">
          <v-chip @click="SelectMultiple">{{ MultipleSelection ? 'Désactiver la selection multiple' : 'Activer la selection multiple' }}</v-chip>
        </v-col>
      </v-row>
    </v-sheet>
    <v-divider />
    <v-list three-line class="py-0" :flat="!MultipleSelection">
      <v-list-item-group v-model="selectedItems" :multiple="MultipleSelection">
        <template v-for="(item, i) in items">
          <v-list-item :key="i" @click="openDocument">
            <template v-slot:default="{ active }">
              <v-list-item-avatar :key="'icon-'+i" tile>
                <transition name="slide-left" mode="out-in">
                  <v-checkbox v-if="MultipleSelection" :key="'checkbox-'+i" :input-value="active" hide-details class="mb-1" dense />
                  <v-img v-else :src="item.icon" />
                </transition>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Créé par : <span class="primary--text">{{ item.shareby }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="text-caption">
                  {{ item.date }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
          <v-divider v-if="i + 1 < items.length" :key="i" />
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
  export default {
    name: 'PatientDocument',
    data: () => ({
      MultipleSelection: false,
      items: [
        {
          uid: 'abc',
          date: '28 mars 2021',
          title: 'Radio du pied',
          shareby: 'Dr Guillaume Paut',
          icon: require('@/assets/img/files/png.png')
        },
        {
          uid: 'der',
          date: '25 janvier 2021',
          title: 'Compte rendu d\'opération',
          shareby: 'Mickael Barre',
          icon: require('@/assets/img/files/txt.png')
        },
        {
          uid: 'uyt',
          date: '24 janvier 2021',
          title: 'Synthèse du rendez-vous',
          shareby: 'Dr Guillaume Paut',
          icon: require('@/assets/img/files/txt.png')
        },
        {
          uid: 'jgh',
          date: '30 novembre 2020',
          title: 'IRM du pied',
          shareby: 'Jean-Baptiste Vercasson',
          icon: require('@/assets/img/files/jpg.png')
        },
        {
          uid: 'aae',
          date: '18 septembre 2020',
          title: 'Bilan hospitalier',
          shareby: 'Mickael Barre',
          icon: require('@/assets/img/files/avi.png')
        }
      ],
      selectedItems: []
    }),
    methods: {
      SelectMultiple () {
        if(!this.MultipleSelection) {
          this.selectedItems = []
        }
        this.MultipleSelection = !this.MultipleSelection
      },
      openDocument () {
        if(!this.MultipleSelection) {
          // eslint-disable-next-line no-console
          console.log('opening document here')
        }
      }
    }
  }
</script>
